export const AMS_BACKEND_API = process.env.REACT_APP_AMS_BACKEND_API
// export const AMS_BACKEND_API = `https://z6n3z73d-7004.inc1.devtunnels.ms`
// export const AMS_BACKEND_API = `http://localhost:7004`

export const CRM_BACKEND_API = process.env.REACT_APP_CRM_BACKEND_API
// export const CRM_BACKEND_API = `https://tn5sc4vj-7004.inc1.devtunnels.ms`

export const AQS_BACKEND_API = process.env.REACT_APP_AQS_BACKEND_API
// export const AQS_BACKEND_API = `https://tn5sc4vj-7003.inc1.devtunnels.ms`
// export const AQS_BACKEND_API = `https://4b6a-182-176-117-109.ngrok-free.app`
// export const AQS_BACKEND_API = `http://localhost:7003`

export const OIDC_BACKEND_API = process.env.REACT_APP_OIDC_BACKEND_API
// export const OIDC_BACKEND_API = `https://eyc4k.localtonet.com`

export const UNSPLASH_ENDPOINT = process.env.REACT_APP_UNSPLASH_ENDPOINT


export const STRIPE_PUBLIC_KEY = process.env.REACT_APP_STRIPE_KEY

export const VERONICAS_QUOTE = process.env.REACT_APP_VERONICAS_QUOTE