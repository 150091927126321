/* eslint-disable no-unused-vars */
import React, { useState, useRef, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { brandLogo, smallLogo } from 'App/Assets'
import { useLocation, NavLink } from 'react-router-dom'
import {
  AMS_BACKEND_API,
  AxiosInstance,
  offset,
  SIDEBAR_ITEMS,
} from 'App/Config'
import {
  SideBarWrapper,
  Brand,
  BrandImage,
  gradientActiveClass,
  SubMenu,
} from './style'
import {
  faArrowAltCircleRight,
  faAngleDoubleRight,
  faSearch,
  faInfoCircle,
} from '@fortawesome/free-solid-svg-icons'
import { isSuperUser, permissionGranted } from 'App/Services'
import { SearchInputField } from 'App/Components/Common/SearchInputField'
import { useMutation } from 'react-query'
import { useDebouncedCallback } from 'use-debounce/lib'
import { useHistory } from 'react-router'
import {
  setGlobalCustomerFilter,
  setGlobalSearchData,
  setIsDataLoading,
  setQuerySearch,
} from 'App/Redux/actions'
import { useDispatch, useSelector } from 'react-redux'
import { dynamicImportComponents } from 'App/Config/dynamicImports'
export const SideBar = () => {
  const dispatch = useDispatch()

  //  filters
  const [sort] = useState(``)

  // const [isMasterFrenchise, setIsMasterFrenchise] = useState(false)

  const [searchQuery, setSearchQuery] = useState(``)
  const [filterCustomer, setFilterCustomer] = useState(false)
  const [isCollapsed, setIsCollapsed] = useState(false)
  const [isSubmenuOpened, setIsSubmenuOpened] = useState(false)
  const [currentSubActive, setCurrentSubActive] = useState(false)

  const [previousActiveItem, setPreviousActiveItem] = useState(``)
  const isSearchedQuery = useRef(null)

  const submenuRef = useRef(0)
  const location = useLocation()

  const history = useHistory()
  const user = useSelector(({ user: { user } }) => user)
  const handleCollapes = () => {
    if (window.innerWidth <= `1100` && !isCollapsed) {
      setIsCollapsed(true)
    }
    if (window.innerWidth >= `1100`) {
      setIsCollapsed(false)
    }
  }

  useEffect(() => {
    window.addEventListener(`resize`, handleCollapes)

    return () => {
      window.removeEventListener(`resize`, handleCollapes)
    }
  }, [])

  const handleItemClick = (item) => {
    if (!isCollapsed) {
      if (isSubmenuOpened && previousActiveItem === item.title) {
        setIsSubmenuOpened(false)
      } else {
        setIsSubmenuOpened(true)
      }
      setPreviousActiveItem(item.title)

      if (item.path) history.push(item.path)
      if (item) setCurrentSubActive(item.title)
    }
    if (isCollapsed) {
      setIsCollapsed(false)
      setCurrentSubActive(item.title)
    }
  }

  const handleCollapseSidebar = () => {
    setIsCollapsed(!isCollapsed)
  }

  const { mutate: searchedGlobalData, isLoading: gettingSearchedData } =
    useMutation(
      `globalSearchApi`,
      async () =>
        await AxiosInstance.get(
          `${AMS_BACKEND_API}/api/dashboard/global-search?${
            searchQuery?.length ? `search=${searchQuery}` : ``
          }&sort="${sort?.length > 0 ? sort : ``}"`,
        ).then((res) => {
          return res?.data?.data
        }),
      {
        refetchOnWindowFocus: false,
        cacheTime: 1,
        onSuccess: (res) => {
          dispatch(setGlobalSearchData(res))
          isSearchedQuery.current = false
          history.push(`/globalSearch/result`)
        },
        onError: () => {
          isSearchedQuery.current = false
        },
      },
    )

  useEffect(() => {
    dispatch(setIsDataLoading(gettingSearchedData))
    dispatch(setQuerySearch(searchQuery))
  }, [gettingSearchedData])
  // useEffect(() => {
  //   const isEmpty = searchQuery.trim()?.length
  //   if (!filterCustomer && isEmpty && !isSearchedQuery.current) {
  //     isSearchedQuery.current = true
  //     setTimeout(() => searchedGlobalData(), 700)
  //   }
  // }, [filterCustomer])

  const searchDataQuery = () => {
    const isEmpty = searchQuery.trim()?.length
    if (isEmpty) {
      if (filterCustomer) {
        history.push(`/globalSearch/result`)
        dispatch(setQuerySearch(searchQuery))
      } else {
        searchedGlobalData()
      }
    }
  }

  const handlePermissionGranted = (item) => {
    let allowed = false

    if (!item.path) {
      let subPermissions = [].concat.apply(
        [],
        item.submenu.map((subItems) => subItems.permission),
      )

      allowed = permissionGranted(
        subPermissions.filter(
          (value, index, self) => self.indexOf(value) === index,
        ),
        true,
      )
    } else {
      allowed = permissionGranted(item.permission)
    }
    return allowed
  }

  // const handleAdminFranchise = (title) => {
  //   if (title !== `Franchises` && title !== `Car Dealers`) {
  //     return true
  //   } else {
  //     if (
  //       !user.profile.system_permissions.includes(`admin-master-franchise`) &&
  //       !user.profile.system_permissions.includes(`admin-franchise`)
  //     ) {
  //       return true
  //     } else {
  //       return false
  //     }
  //   }
  // }

  return (
    <SideBarWrapper collapsed={isCollapsed.toString()}>
      <div className="flex h-screen overflow-y-scroll hide-scroller hide-scroller-firefox flex-col justify-between">
        <div className="relative">
          <Brand className="top-0 z-40 sticky cursor-pointer">
            <BrandImage
              src={isCollapsed ? smallLogo : brandLogo}
              alt="brand"
              collapsed={isCollapsed.toString()}
              onClick={() => history.push(`/dashboard`)}
            />
            <span
              onClick={() => handleCollapseSidebar()}
              className="absolute inset-y-0 right-1 top-1 transform translate-y-1 cursor-pointer "
            >
              {/* <CollapseClip> */}
              <FontAwesomeIcon
                icon={faArrowAltCircleRight}
                className={`text-gray-200 my-0.5 cursor-pointer transform transition duration-500 ${
                  isCollapsed ? ` rotate-0  ` : `rotate-180  `
                }`}
              />
              {/* </CollapseClip> */}
            </span>
          </Brand>

          {/* Search Box */}
          {permissionGranted([
            `global-search:read`,
            `corporate-manager:*`,
            `manager:*`,
            `admin:*`,
          ]) && (
            <div className="px-2 my-2">
              {isCollapsed ? (
                <span>
                  <FontAwesomeIcon
                    icon={faSearch}
                    className={`cursor-pointer text-white w-full text-center`}
                    size="lg"
                    onClick={() => setIsCollapsed(!isCollapsed)}
                  />
                </span>
              ) : (
                <div className="relative">
                  <SearchInputField
                    type="text"
                    validating={gettingSearchedData}
                    placeholder="Search..."
                    value={searchQuery}
                    setValue={(e) => {
                      setSearchQuery(e.target.value)
                      // searchDataQuery()
                    }}
                    resetButton={true}
                    resetValue={() => {
                      setSearchQuery(``)
                      dispatch(setGlobalSearchData(``))
                      setFilterCustomer(false)
                      dispatch(setGlobalCustomerFilter(false))
                    }}
                    onEnter={(e) => {
                      if (e.key === `Enter`) {
                        searchDataQuery()
                      }
                    }}
                  />
                  {!gettingSearchedData && (
                    <div
                      className="z-40 absolute top-1.5 right-1.5 tooltip tooltip-left"
                      data-tip="Find Customer Anywhere"
                    >
                      <FontAwesomeIcon
                        icon={faInfoCircle}
                        className={`${
                          filterCustomer ? `text-red-500` : `text-gray-600  `
                        } text-2xs text-center cursor-pointer`}
                        size="lg"
                        onClick={() => {
                          setFilterCustomer(!filterCustomer)
                          dispatch(setGlobalCustomerFilter(!filterCustomer))
                        }}
                      />
                      {/* <p
                      onClick={() => {
                        setFilterCustomer(!filterCustomer)
                        dispatch(setGlobalCustomerFilter(!filterCustomer))
                      }}
                    >
                      i
                    </p> */}
                    </div>
                  )}
                </div>
              )}
            </div>
          )}
          {/* <hr className="mb-2" color="red" /> */}

          <div className="relative mt-2 z-0">
            {SIDEBAR_ITEMS &&
              SIDEBAR_ITEMS?.map((item) => {
                const currentActive = location.pathname == item.path
                const { submenu } = item

                if (
                  (item?.name === `CAR_DEALER` &&
                    isSuperUser(`super-admin:*`)) ||
                  (item?.name === `CAR_DEALER` &&
                    isSuperUser(`system-admin:*`)) ||
                  ((item?.name == `crm` || item?.name == `Messenger`) &&
                    (user.profile.system_permissions.includes(
                      `car-dealer-manager:*`,
                    ) ||
                      user.profile.system_permissions.includes(
                        `car-dealer-agent:*`,
                      )))
                ) {
                  return null
                }

                return (
                  handlePermissionGranted(item) && (
                    <div key={item.title}>
                      <div
                        className={`cursor-pointer font-thin text-gray-200 p-2 ${
                          (currentActive ||
                            location.pathname.includes(item.name)) &&
                          gradientActiveClass
                        }`}
                        onMouseOverCapture={dynamicImportComponents[item?.path]}
                        onClick={() => handleItemClick(item)}
                      >
                        <div
                          className={`w-full flex flex-row items-center  ${
                            !isCollapsed ? ` justify-between` : `justify-center`
                          } `}
                        >
                          <div className="flex flex-row justify-center items-center">
                            <div
                              data-tip={item.title}
                              className="tooltip tooltip-right w-4 text-center"
                            >
                              <img src={item.icon} alt="img" />
                            </div>

                            {!isCollapsed && (
                              <span className="mx-4 text-sm font-normal flex justify-end gap-4">
                                {item.title}
                              </span>
                            )}
                          </div>
                          {!isCollapsed && item.submenu && (
                            <FontAwesomeIcon
                              className={`transform transition ${
                                isSubmenuOpened &&
                                currentSubActive === item.title
                                  ? ` rotate-90  `
                                  : `rotate-0  `
                              }`}
                              icon={faAngleDoubleRight}
                            />
                          )}
                        </div>
                      </div>
                      <div className="ml-3.5">
                        {!isCollapsed && (
                          <div>
                            {submenu &&
                              submenu.map((_submenu) => {
                                const currentSubMenuActive =
                                  location.pathname.includes(_submenu.path)
                                // restrict system admin for credentials and policy report
                                if (
                                  // _submenu.path === `/admin/credentials` ||
                                  _submenu.path === `/reports/policy-reports` &&
                                  user.profile.system_permissions.includes(
                                    `system-admin:*`,
                                  )
                                ) {
                                  return null
                                }
                                return (
                                  permissionGranted(_submenu.permission) && (
                                    <div
                                      key={_submenu.title}
                                      ref={submenuRef}
                                      className={`max-h-0 border-l-1 border-red-500 bg-gray-75 ${
                                        currentSubMenuActive &&
                                        gradientActiveClass
                                      }  overflow-hidden  transition-height ease-linear  duration-200  delay-150 ${
                                        !isCollapsed &&
                                        isSubmenuOpened &&
                                        currentSubActive === item.title
                                          ? `max-h-96  `
                                          : ` max-h-0  `
                                      } `}
                                      onMouseOverCapture={
                                        dynamicImportComponents[_submenu?.path]
                                      }
                                    >
                                      <NavLink
                                        className={`flex flex-row justify-start items-center ml-6`}
                                        to={_submenu.path}
                                      >
                                        <div className="w-4">
                                          {_submenu.icon && (
                                            <img
                                              src={_submenu.icon}
                                              alt="img"
                                            />
                                          )}
                                        </div>

                                        <SubMenu>{_submenu.title}</SubMenu>
                                      </NavLink>
                                      <div></div>
                                    </div>
                                  )
                                )
                              })}
                          </div>
                        )}
                      </div>
                    </div>
                  )
                )
              })}
          </div>
        </div>
        <div className="flex justify-end text-gray-200 px-4 py-2  w-full text-2xs">
          {/* majorrelease.newFeature.bugsFixing */}
          version: 1.15.50
        </div>
      </div>
    </SideBarWrapper>
  )
}
