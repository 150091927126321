import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Badge } from '@windmill/react-ui'
import { CardField } from 'App/Components/Common/CardField'
import { ImageModal } from 'App/Components/Common/ImageModal'

import { Reload } from 'App/Components/Common/Reload'
import { AMS_BACKEND_API, AxiosInstance } from 'App/Config'
import { setCurrentTask } from 'App/Redux/actions'
import { permissionGranted } from 'App/Services'
import { Container, PageHeader } from 'App/Styled'
import React, { useEffect, useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import { useDispatch } from 'react-redux'
import { useRouteMatch } from 'react-router'
import { useSelector } from 'react-redux'
import moment from 'moment'
import { DATE_FORMATS } from 'App/constants'
export const AboutTask = () => {
  const {
    params: { id },
  } = useRouteMatch()

  const [preview, setPreview] = useState(false)
  const [currentImage, setCurrentImage] = useState(``)
  const notificationRead = useSelector(
    ({ notification }) => notification?.isRead,
  )
  const dispatch = useDispatch()
  const {
    data: taskData,
    isLoading,
    error,
    refetch,
    isFetching,
  } = useQuery(
    `TaskPreviewApi`,
    async () =>
      await AxiosInstance.get(`${AMS_BACKEND_API}/api/task/${id}`).then(
        (res) => {
          return res.data.data
        },
      ),
    {
      refetchOnWindowFocus: false,
      cacheTime: 1,
      onSuccess: (res) => {
        dispatch(setCurrentTask(res))
      },
    },
  )
  const { mutate: updateStatus } = useMutation(
    async (payload) =>
      await AxiosInstance.patch(`${AMS_BACKEND_API}/api/task/${id}`, {
        ...payload,
      }).then((res) => {
        refetch()
        if (res) {
          NotificationManager.success(`Task status updated`)
        }

        if (res.hasError) NotificationManager.error(hasError)
      }),
    {
      refetchOnWindowFocus: false,
      enabled: false,
    },
  )
  const updatePayload = () => {
    updateStatus({
      status: `CLOSED`,
    })
  }

  const badgeColor = {
    LOW: `success`,
    MEDIUM: `warning`,
    HIGH: `danger`,
    OPENED: `success`,
    CLOSED: `danger`,
    OVERDUE: `warning`,
    TASKID: `warning`,
  }

  useEffect(() => {
    if (id) {
      refetch()
    }
  }, [id])

  useEffect(() => {
    refetch()
  }, [notificationRead])

  return (
    <>
      <Container>
        <PageHeader>
          <div className="flex ">
            <div className=" ml-5 flex my-auto gap-x-2">
              <div
                className=" outline-none border-0 tooltip tooltip-bottom cursor-pointer"
                data-tip="Task Id"
              >
                <Badge type={badgeColor[`OPENED`]}>{taskData?._taskId}</Badge>
              </div>
              <div
                className=" outline-none border-0 tooltip tooltip-bottom cursor-pointer"
                data-tip="Task Priority"
              >
                <Badge type={badgeColor[taskData?.priority]}>
                  {taskData?.priority}
                </Badge>
              </div>
              <div
                className=" outline-none border-0 tooltip tooltip-bottom cursor-pointer"
                data-tip="Task Status"
                onClick={updatePayload}
              >
                <Badge type={badgeColor[taskData?.status]}>
                  {taskData?.status}
                </Badge>
              </div>
              <div
                className=" outline-none border-0 tooltip tooltip-bottom cursor-pointer"
                data-tip="Task Type"
              >
                <Badge type="primary">{taskData?.taskType}</Badge>
              </div>
              {taskData?.status &&
                taskData?.status !== `CLOSED` &&
                permissionGranted([
                  `task:update`,
                  `corporate-manager:*`,
                  `manager:*`,
                  `admin:*`,
                ]) && (
                  <div
                    className=" outline-none border-0  tooltip tooltip-bottom cursor-pointer"
                    data-tip="Change Status"
                    onClick={updatePayload}
                  >
                    <Badge type="danger">Change Task Status</Badge>
                  </div>
                )}
            </div>
          </div>
        </PageHeader>

        {preview && currentImage && (
          <ImageModal
            currentImage={currentImage}
            setPreview={setPreview}
            setCurrentImage={setCurrentImage}
          />
        )}

        <div className="bg-white sm:mb-0 justify-between w-full px-2 rounded-md">
          {isLoading || isFetching ? (
            <div className="text-center py-10">
              <FontAwesomeIcon
                icon={faSpinner}
                spin={true}
                size="1x"
                className="text-red-500"
              />
            </div>
          ) : error ? (
            <Reload refetch={() => refetch()} />
          ) : (
            <div className="m-2 pb-4 ">
              <div className="flex items-start justify-evenly ">
                <div className="flex-1 mx-2 rounded">
                  <div className="flex px-2">
                    <div className="flex-1">
                      <CardField
                        title="Assigned To"
                        value={taskData?.assignTo}
                      />
                      <CardField
                        title="Due Date"
                        badge={true}
                        value={
                          taskData?.dueDate
                            ? moment(taskData?.dueDate).format(
                                DATE_FORMATS.DATE_TIME_ALT,
                              )
                            : taskData?.dueDate
                        }
                      />
                      <CardField
                        title="Recurring Frequency"
                        value={taskData?.recurring.recurringCustomType}
                      />
                    </div>

                    <div className="flex-1">
                      <CardField
                        title="Assigned By"
                        value={taskData?.assignBy}
                      />
                      <CardField
                        title="Recurring Type"
                        value={taskData?.recurring.recurringType}
                      />
                      <CardField
                        title="Recurring after"
                        value={taskData?.recurring.recurringAfter}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </Container>
      <Container>
        <PageHeader>
          <span className="tooltip" data-tip="Title">
            {taskData?.title}
          </span>
        </PageHeader>
        <div className="text-sm p-4 ">{taskData?.description}</div>
      </Container>
    </>
  )
}
